import { CONTEXT_PATH } from '@confluence/named-routes';

import type { EditorPropertyType } from './editorVersion';

interface BypassArgs {
	url: string;
	spaceKey: string;
	contentType: string | null | undefined;
	editorVersion: EditorPropertyType;
	contentId: string | number;
	fabricEditorSupported?: boolean;
	// Some use cases such as editUi or webUi do not use CONTEXT_PATH
	noContextPath?: boolean;
}

export const getURLBypassingResumeDraftAction = ({
	url,
	spaceKey,
	contentType,
	editorVersion,
	contentId,
	fabricEditorSupported,
	noContextPath,
}: BypassArgs): string => {
	const isPage = contentType === 'page';
	const isBlog = contentType === 'blogpost';
	const isPageOrBlog = isPage || isBlog;
	const isUsingFabricEditor = fabricEditorSupported || editorVersion === 'v2'; // as opposed to the v1 TinyMCE editor
	const providedURLHitsResumeDraftAction = url.includes('resumedraft.action');

	if (isPageOrBlog && isUsingFabricEditor && providedURLHitsResumeDraftAction) {
		const contentTypeForURL = isPage ? 'pages' : 'blog';
		const contentIdForURL = contentId;
		// Extract the query string from the relative URL.
		const queryString = url.includes('?') ? url.slice(url.indexOf('?') + 1) : '';
		// Extract the `draftShareId` from the query string.
		const urlSearchParams = new URLSearchParams(queryString);
		const draftShareId = urlSearchParams.get('draftShareId');
		const draftShareIdParamString = draftShareId ? `draftShareId=${draftShareId}` : '';
		const optionalContextPath = noContextPath ? '' : CONTEXT_PATH;
		const bypassURL = `${optionalContextPath}/spaces/${spaceKey}/${contentTypeForURL}/edit-v2/${contentIdForURL}?${draftShareIdParamString}`;

		return bypassURL;
	} else {
		return url;
	}
};
