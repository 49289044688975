import type { FC } from 'react';
import React, { useEffect } from 'react';
import { styled } from '@compiled/react';

import { useSSRPlaceholderReplaceIdProp } from '@confluence/loadable';
import { fg } from '@confluence/feature-gating';

import type { SSREventListener } from './SSREventListener';

declare global {
	interface Window {
		__SSR_EVENT_LISTENERS__: SSREventListener[];
		__SSR_INTERACTIONS__?: { [key: string]: boolean };
		__SSR_INTERACTIONS_DURATIONS__?: { [key: string]: Array<number> };
	}
}

// Made func global to remove duplication for scripts and event handlers during and after SSR
function addSsrMetric(metric: string) {
	if (window.__SSR_INTERACTIONS__ && window.__SSR_INTERACTIONS_DURATIONS__) {
		window.__SSR_INTERACTIONS__[metric] = true;
		window.__SSR_INTERACTIONS_DURATIONS__[metric] = [
			...(window.__SSR_INTERACTIONS_DURATIONS__[metric] || []),
			performance.now(),
		];
	}
}

export type Props = {
	metricName: string;
	isPlaceholder?: boolean;
	children?: React.ReactNode;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DivStyledWrapper = styled.div({
	display: 'inline-block',
	position: 'relative',
	'&::after': {
		content: '',
		position: 'absolute',
		top: 0,
		left: 0,
		bottom: 0,
		right: 0,
	},
});

export const SSRMouseEventWrapper: FC<Props> = ({ metricName, isPlaceholder, children }) => {
	const ssrPlaceholderIdProp = useSSRPlaceholderReplaceIdProp();
	useEffect(() => {
		(window.__SSR_EVENT_LISTENERS__ || []).forEach((ssrEventListener) => {
			const [target, type, listener, options] = ssrEventListener;
			target.removeEventListener(type, listener, options);
		});
		window.__SSR_EVENT_LISTENERS__ = [];
	}, []);

	if (
		(!process.env.REACT_SSR && (!window.__SSR_RENDERED__ || !isPlaceholder)) ||
		fg('confluence_ssr_remove_action_button_clicks')
	) {
		// Top nav items were non-actionable but not placeholders, they do not need this wrapper after transitioning to SPA
		return <>{children}</>;
	}

	if (!process.env.REACT_SSR && isPlaceholder) {
		// SPA took over but placeholders are still attached and we still want durations until they are gone
		return (
			<DivStyledWrapper
				{...ssrPlaceholderIdProp}
				data-vc="ssr-mouse-event-wrapper"
				data-ssr-metric-name={metricName}
				onClick={() => addSsrMetric(`${metricName}_CLICK`)}
				onMouseEnter={() => addSsrMetric(`${metricName}_HOVER`)}
			>
				<>{children}</>
			</DivStyledWrapper>
		);
	}

	return (
		<>
			{children}
			<input
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className="ssrMouseEvent"
				data-testid="ssrMouseEvent"
				type="hidden"
				value={metricName}
			/>
		</>
	);
};
