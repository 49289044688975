// SSR metrics that are attached via window.__ADD_SSR_METRIC__ with _CLICK or _HOVER appended to name
// these are attached to BM event via ssrInteractionsDurations (see pageLoadEventPlugin.js)
export const SSR_NAV_SEARCH_METRIC = 'SSR_NAV_SEARCH';
export const SSR_COMMENT_BUTTON_METRIC = 'SSR_COMMENT_BUTTON';
export const SSR_AUTOMATION_BUTTON_METRIC = 'SSR_AUTOMATION_BUTTON';
export const SSR_SHARE_BUTTON_METRIC = 'SSR_SHARE_BUTTON';
export const SSR_REACTIONS_BUTTON_METRIC = 'SSR_REACTIONS_BUTTON';
export const SSR_LABELS_BUTTON_METRIC = 'SSR_LABELS_BUTTON';
export const SSR_WATCH_BUTTON_METRIC = 'SSR_WATCH_BUTTON';
export const SSR_FAVORITE_BUTTON_METRIC = 'SSR_FAVORITE_BUTTON';
export const SSR_EDIT_BUTTON_METRIC = 'SSR_EDIT_BUTTON';
export const SSR_RESTRICTIONS_BUTTON_METRIC = 'SSR_RESTRICTIONS_BUTTON';
export const SSR_CONTENT_TOOLS_METRIC = 'SSR_CONTENT_TOOLS';
export const SSR_NAV_APP_SWITCHER_METRIC = 'SSR_NAV_APP_SWITCHER';
export const SSR_NAV_APPS_BUTTON_METRIC = 'SSR_NAV_APPS_BUTTON';
export const SSR_NAV_CREATE_METRIC = 'SSR_NAV_CREATE';
export const SSR_NAV_CUSTOM_HOMEPAGE_BUTTON_METRIC = 'SSR_NAV_CUSTOM_HOMEPAGE_BUTTON';

export const SSR_NAV_COMPANY_HUB_BUTTON_METRIC = 'SSR_NAV_CUSTOM_HOMEPAGE_BUTTON';
export const SSR_NAV_HELP_METRIC = 'SSR_NAV_HELP';
export const SSR_NAV_HOME_BUTTON_METRIC = 'SSR_NAV_HOME_BUTTON';
export const SSR_NAV_NOTIFICATIONS_METRIC = 'SSR_NAV_NOTIFICATIONS';
export const SSR_NAV_PAGES_BUTTON_METRIC = 'SSR_NAV_PAGES_BUTTON';
export const SSR_NAV_PEOPLE_BUTTON_METRIC = 'SSR_NAV_PEOPLE_BUTTON';
export const SSR_NAV_PROFILE_METRIC = 'SSR_NAV_PROFILE';
export const SSR_NAV_SETTINGS_METRIC = 'SSR_NAV_SETTINGS';
export const SSR_NAV_SPACES_BUTTON_METRIC = 'SSR_NAV_SPACES_BUTTON';
export const SSR_NAV_DRAFTS_BUTTON_METRIC = 'SSR_NAV_DRAFTS_BUTTON';
export const SSR_NAV_TASKS_BUTTON_METRIC = 'SSR_NAV_TASKS_BUTTON';
export const SSR_NAV_TEMPLATES_BUTTON_METRIC = 'SSR_NAV_TEMPLATES_BUTTON';
export const SSR_PAGE_TREE_NON_LINK_METRIC = 'SSR_PAGE_TREE_NON_LINK';
export const SSR_FOCUSED_INLINE_COMMENT_METRIC = 'SSR_FOCUSED_INLINE_COMMENT';
export const SSR_ADMIN_ANNOUNCEMENT_BANNER_DISMISS_BUTTON_METRIC =
	'SSR_ADMIN_ANNOUNCEMENT_BANNER_DISMISS_BUTTON';
export const SSR_ADMIN_ANNOUNCEMENT_BANNER_CONTENT_LINK_METRIC =
	'SSR_ADMIN_ANNOUNCEMENT_BANNER_CONTENT_LINK';
